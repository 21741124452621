import { twJoin, twMerge } from "tailwind-merge";
import bg from "../img/sat-bg.png";
import logo from "../img/sat-logo.svg";
import styles from "./LogInComponent.module.scss";
import { switchEyeHandler } from "../../reset-password/components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../app/services/login";
import axios from "../../axios";
import { change_axios_language, endpointUrl, ssoEndPointUrl, ssoReqEndPointUrl } from "../../app/services/api";
// import { requestPermission } from "../../app/services/firebase";
import { checkAllPersmissions } from "../../auth/components/SectionRoute";
import { useNavigate } from "react-router-dom";
import DisclaimerPopup from "../../components/NoticePopup/DisclaimerPopup";
import { requestPermission } from "../../app/services/firebase";
import {
  useGetCMSDataQuery,
  useGetLogoDataQuery,
} from "../../app/services/cms";
import { SavePassword } from "capacitor-ios-autofill-save-password";
import { Capacitor } from "@capacitor/core";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { withNamespaces } from "react-i18next";
import i18n from "../../i18n";
import { changeLanguage } from "i18next";
//import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { DefaultSystemBrowserOptions, DefaultWebViewOptions, InAppBrowser } from '@capacitor/inappbrowser';
import authHeader from "../../app/services/auth-header";
// import { InAppBrowser } from '@capgo/inappbrowser'
const LogInComponent = (props: any) => {
  const { logoData, t } = props;
  const { data: cmsData, isLoading } = useGetCMSDataQuery("disclaimer");
  const navigate = useNavigate();
  const cookie = window.localStorage.getItem("disclaimer") ? false : true;
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const [ssoLoading, setSSOLoading] = useState<boolean>(false);
  const [open, setIsOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [disclaimer, setDisclaimer] = useState<any>();
  const [loginError, setLoginError] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    isRemember: false,
  });
  useEffect(() => {
    change_axios_language();
  }, []);
  useEffect(() => {
    if (cmsData) {
      setDisclaimer(cmsData.cms);
      setIsOpen(cookie);
    }
  }, [cmsData]);
  useEffect(() => {
    const rmeStore = localStorage.getItem("rememberMe");
    if (rmeStore == "true") {
    }
  }, []);
  useEffect(() => {
    const username = localStorage.getItem("rememberedUsername");
    const password = localStorage.getItem("rememberedPassword");
    const rmeStore = localStorage.getItem("rememberMe");
    if (rmeStore == "true") {
      setFormData({
        username: username ?? "",
        password: password ?? "",
        isRemember: false,
      });
    }
    const lng = window.localStorage.getItem('language');
    i18n.changeLanguage(lng ? lng : 'en');

  }, []);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name == "username") {
      localStorage.setItem("email", value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: name == "isRemember" ? (value == "on" ? true : false) : value,
    }));
  };
  const login = (event: any) => {
    if (formData.isRemember == true) {
      localStorage.setItem("rememberedUsername", formData.username);
      localStorage.setItem("rememberedPassword", formData.password);
    } else {
      localStorage.removeItem("rememberedUsername");
      localStorage.removeItem("rememberedPassword");
    }
    handleSubmit(formData);
  };
  function sendMessageToApp(url: string) {
    console.log("browser was sendMessageToApp ", url)
    window.postMessage({ type: 'urlChange', url });
  }
  const openInWebView = async (url: string) => {
    await InAppBrowser.openInSystemBrowser({ url,options: DefaultSystemBrowserOptions }).then((res) => {
      const url = window.location;
      console.log("res", res, url)
    });
    await InAppBrowser.addListener('browserClosed', async () => {
      const url = window.location;
      console.log("browser was closed.", JSON.stringify(url));

    });
    await InAppBrowser.addListener('browserPageLoaded', async () => {
      console.log("browser was loaded.", JSON.stringify(url));
    });
    const url_outside = window.location;
    App.addListener('appUrlOpen', (data: any) => {
      console.log('browser war appUrlOpen:', JSON.stringify(data));
      const url = data.url;
      if (url.startsWith('satshk://')) {
        const url = new URL(data.url);
        const path = url?.pathname?url?.pathname?.replace("//",''):url.href?.replace('satshk://', ''); 
        localStorage.setItem('token', path);
        console.log('localstorage after set ',path.replaceAll('satshk://',''),url)
        getUserInfo(path)
      }
    });
  };
  const loginSSO = async (event: any) => {
   // setCustomLoading(true);
    await axios.get(ssoEndPointUrl+'saml2/sats/login').then(async (result) => {
      console.log("result ", result)
      const info = result?.data;
      const samlRequest = info?.SAMLRequest;
      const login_url = info?.url;
      //const url = `?SAMLRequest=${samlRequest}&RelayState=%2F&sso_reload=true`;      
      const url = login_url+`?SAMLRequest=${samlRequest}&RelayState=%2F&sso_reload=true`;
      openInWebView(url);
     
    })
  }
  const getUserInfo = async (token: string) => {
    console.log("call getUserInfo ",token)
    localStorage.setItem('token', token);
    await axios.get(endpointUrl+'fetchuser', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': `application/json`,
        'Content-Type': `application/json`,
        // 'Cookie': `document_session=${user.accessToken}`,
        'type': 'application/octet-stream',
      }
    }).then(async (result) => {
      const user = result?.data;
      const user_info = {
        "id": user.id,
        "accessToken": token,
        "user": user?.user,
        "roles": [],
        "permissions": [],
        "message": "Login successful"
      }
      console.log("JSON.stringify(user) ",JSON.stringify(user_info))
      if(user_info){
        localStorage.setItem("userData", JSON.stringify(user_info));
      }
      await InAppBrowser.close();
      navigate('/');
    })
  }
  useEffect(() => {
    console.log("login ", window.location)
  }, []);
  const handleSubmit = (formData: any) => {
    setCustomLoading(true);
    if (formData.username == '' || formData.password == '') {
      setLoginError(t('login_error'));
      setCustomLoading(false);
      return;
    }
    axios
      .post(endpointUrl + "login", {
        email: formData.username,
        password: formData.password ?? "",
      })
      .then(function (response) {
       // localStorage.removeItem("userData");
        var data = response.data;
        var userData = JSON.stringify(data);
        localStorage.setItem("username", formData.username);
        localStorage.setItem("password", formData.password);
        if (formData.isRemember == true) {
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.setItem("rememberMe", "false");
        }

        localStorage.setItem("userData", userData);
        if (userData) {
          const userJson = JSON.parse(userData);
          localStorage.setItem("qr_in_code", userJson?.user?.employee?.qr_in_code);
          localStorage.setItem("qr_out_code", userJson?.user?.employee?.qr_out_code);
        }
        saveData();
        requestPermission();
        if (Capacitor.getPlatform() === "ios") {
          console.log("ios ", formData);
          SavePassword.promptDialog({
            username: formData.username,
            password: formData.password,
          })
            .then(() => console.log("promptDialog success"))
            .catch((err) => console.error("promptDialog failure", err));
        }
        if (response?.data?.status == true) {
          if (
            data?.permissions?.find(
              (x: any) => x.name == "View_Admin_Dashboard"
            )
          ) {
            // console.log("dashboard", data?.permissions.find((x: any) => x.name == "View_Admin_Dashboard"))
            navigate("/");
          } else if (
            data?.permissions?.find((x: any) => x.name == "View_My_Profile")
          ) {
            navigate("/profile");
          } else {
            checkAllPersmissions(navigate, userData);
          }
        } else {
          setLoginError(response?.data?.message);
          setCustomLoading(false);
        }
        setCustomLoading(false);
      })
      .catch((reason: any) => {
        setCustomLoading(false);
        if (reason.response) {
          var error = reason.response.data.message;
          console.log("error ", error);
          // var error=reason.response.data.output.error.ErrorMessage;
          setLoginError(error);
          setCustomLoading(false);
        } else if (reason.message) {
          setCustomLoading(false);
          setLoginError(reason.message);
        }
      });
  };
  const saveData = async () => {
    try {
      await SecureStoragePlugin.set({
        key: "user",
        value: JSON.stringify(formData),
      });
      console.log("Password saved successfully!");
    } catch (error: any) {
      console.log(`Error saving password: ${error.message}`);
    }
  };
  // const getPassword = async () => {
  //   try {
  //     const { value } = await SecureStoragePlugin.get({ key: "user_password" });
  //     setFormData(value?JSON.parse(value):'')
  //     console.log(`Retrieved password: ${value}`);
  //   } catch (error: any) {
  //     console.log(`Error retrieving password: ${error}`);
  //   }
  // };
  // getPassword();
  return (
    <div className="max-w-full overflow-hidden login-form md:max-w-[360px] mx-auto">
      <div className="max-w-full mx-auto overflow-hidden">
        <div>
          <div
            className="relative overflow-hidden"
          >
            <img
              src={logoData?.banner ? logoData?.banner : bg}
              alt="bg"
              className={twJoin(
                styles.bg_image,
                "w-full max-w-full object-cover object-bottom"
              )}
            />
          </div>
          <div className="px-9 pb-20 justify-end relative z-10">
            <div>
              <img
                src={logoData?.logo ? logoData?.logo : logo}
                alt="logo"
                className="max-w-[185px] w-full object-contain mx-auto mb-[43px]"
              />
              <form action="" className="w-full">
                <div className="mb-3">
                  <input
                    value={formData.username}
                    onChange={handleChange}
                    type="email"
                    name="username"
                    placeholder={t("username")}
                    autoComplete="email"
                    className="text-body-16 w-full font-secondary px-3 py-[10px] bg-sat_lightgray border border-sat_lightgray rounded-lg focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
                  />
                </div>
                <div className="password-div mb-5 flex justify-between items-center overflow-hidden bg-sat_lightgray border border-sat_lightgray rounded-lg relative">
                  <input
                    value={formData.password}
                    onChange={handleChange}
                    type="password"
                    name="password"
                    placeholder={t("password")}
                    autoComplete="password"
                    className="text-body-16 w-full font-secondary px-3 py-[10px] focus-within:outline-none focus:outline-none bg-sat_lightgray border border-sat_lightgray rounded-lg focus-visible:outline-none placeholder:text-sat_grayHint"
                  />
                  <button
                    onClick={(event) => switchEyeHandler(event, t)}
                    type="button"
                    className="text-body-14 text-sat_darkgray font-secondary px-3 absolute right-0 top-1/2 -translate-y-1/2"
                  >
                    {t("show")}
                  </button>
                </div>
                <div className="mb-5 text-left">
                  <label
                    htmlFor="remember_me"
                    className="text-body-16 text-sat_darkgray font-secondary inline-flex items-center"
                  >
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      id="remember_me"
                      name="isRemember"
                      className="overflow-hidden border border-sat_primary relative mr-2 w-6 h-6 accent-white rounded-4 before:rounded-4 before:content-normal before:block before:w-6 before:h-6 before:bg-transparent checked:before:bg-sat_primary checked:after:content-normal checked:after:block checked:after:w-4 checked:after:h-2 checked:after:bg-transparent checked:after:border-l-[3px] checked:after:border-l-white checked:after:border-b-[3px] checked:after:border-b-white checked:after:absolute checked:after:left-1/2 checked:after:top-[42%] checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:rotate-[-45deg] checked:after:rounded-[1px]"
                    />
                    <span>{t("remember_me")}</span>
                  </label>
                </div>
                <p className="text-body-sm font-normal text-center text-sat_primary my-2">
                  {loginError}
                </p>
                <div>
                  <button
                    type="button"
                    onClick={(event) => login(event)}
                    className={twJoin(
                      customLoading ? "opacity-50 pointer-events-none" : "",
                      "rounded-lg py-2 px-3 text-white text-center text-body-md font-bold font-secondary w-full bg-sat_primary"
                    )}
                  >
                    {t("login")}
                  </button>
                </div>
              </form>
              <button
                    type="button"
                    onClick={(event) => loginSSO(event)}
                    className={twJoin(
                      // customLoading ? "opacity-50 pointer-events-none" : "",
                      "mt-3 rounded-lg py-2 px-3 text-sat_primary text-center text-body-md font-bold font-secondary w-full bg-white border border-sat_primary"
                    )}
                  >
                    {t("loginSSO")}
                  </button>
              <div className="mt-5 inline-block">
                <a
                  href="/forgetpassword"
                  className="underline text-sat_primary text-body-14 font-secondary font-normal w-full  inline-block text-center"
                >
                  {t("forget_password")}
                </a>
              </div>
              <p className="text-center mt-8 text-body-14 text-sat_darkblackv2 font-secondary font-normal">
                v1.0.1.1
              </p>
            </div>
          </div>
        </div>
      </div>
      <DisclaimerPopup
        isOpen={open}
        setIsOpen={setIsOpen}
        disclaimer={disclaimer}
      />
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(LogInComponent);
